import { useSingleSubmission, useSubmissionsWithFiltering } from '@/data/submission'
import { IOrganization } from '@/interfaces/IOrganization'
import { ISubmission, ISubmissionFilters } from '@/interfaces/ISubmission'
import React, { useCallback, useEffect, useState } from 'react'
import { statusColorData } from './Status'
import Loader from './Loader'
import { InView } from 'react-intersection-observer'
import { PlusIcon } from '@heroicons/react/solid'
import { IUser } from '@/interfaces/IUser'
import CreatePost from './CreatePost'
import PopupWrapper from './PopupWrapper'
import { AnimatePresence, motion } from 'framer-motion'
import { useDndMonitor, useDroppable } from '@alissavrk/dnd-kit-core'
import DraggableElement from './DraggableElement'
import RoadmapElement from './RoadmapElement'
import { updateSubmissionInfo } from 'network/lib/submission'
import {
  cn,
  generateRoadmapFilter,
  generateRoadmapMainFilters,
  retrieveDateWithoutTimezone,
} from '@/lib/utils'
import debounce from 'lodash/debounce'
import EmptyIllustration from './EmptyIllustration'
import { toast as sonnerToast } from 'sonner'
import { toast } from 'react-hot-toast'
import MessageComponent from './MessageComponent'
import { Transition } from '@headlessui/react'
import { useAtom } from 'jotai'
import { fullRoadmapAtom } from '@/atoms/orgAtom'
import { Trans, useTranslation } from 'next-i18next'
import { sortBy } from './PopularitySorter'
import { monthNames } from './MainRoadmapView'
import { can, isMember } from '@/lib/acl'
import { mutate } from 'swr'
import { tagColorData } from './AddTagModal'

function getLastDayOfMonth(year: number, month: number): Date {
  return new Date(year, month + 1, 0)
}

function parseInput(input: string): { period: string; year: number } {
  const monthOrQuarterRegex = /([\w']+)(?:\s|)'(\d{2})/
  const match = input.toLowerCase().match(monthOrQuarterRegex)

  if (!match) {
    throw new Error(`Invalid input: ${input}`)
  }

  const [, period, yearStr] = match
  const year = 2000 + parseInt(yearStr, 10)

  return { period, year }
}

export function storeDateWithoutTimezone(inputDate: Date) {
  const year = inputDate.getFullYear()
  const month = inputDate.getMonth()
  const day = inputDate.getDate()
  const storedDate = new Date(year, month, day, 12, 0, 0, 0)
  return storedDate
}

function createUTCDate(
  year: number,
  month: number,
  day: number,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0
): string {
  return new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds)).toISOString()
}

export function getEndDate(input: string): string {
  const { period, year } = parseInput(input)

  switch (period) {
    case 'q1':
      return createUTCDate(year, 2, 31, 23, 59, 59, 999)
    case 'q2':
      return createUTCDate(year, 5, 30, 23, 59, 59, 999)
    case 'q3':
      return createUTCDate(year, 8, 30, 23, 59, 59, 999)
    case 'q4':
      return createUTCDate(year, 11, 31, 23, 59, 59, 999)
    default:
      const monthIndex = monthNames.indexOf(period)
      if (monthIndex >= 0) {
        // Get the last day of the month
        const lastDay = new Date(Date.UTC(year, monthIndex + 1, 0)).getUTCDate()
        return createUTCDate(year, monthIndex, lastDay, 23, 59, 59, 999)
      }
      throw new Error(`Invalid input: ${input}`)
  }
}

export function getStartDate(input: string): string {
  const { period, year } = parseInput(input)

  switch (period) {
    case 'q1':
      return createUTCDate(year, 0, 1)
    case 'q2':
      return createUTCDate(year, 3, 1)
    case 'q3':
      return createUTCDate(year, 6, 1)
    case 'q4':
      return createUTCDate(year, 9, 1)
    default:
      const monthIndex = monthNames.indexOf(period)
      if (monthIndex >= 0) {
        return createUTCDate(year, monthIndex, 1)
      }
      throw new Error(`Invalid input: ${input}`)
  }
}

const DedicatedRoadmapField: React.FC<{
  org: IOrganization
  user?: IUser
  status: ISubmission['postStatus'] | { id: string; name: string }
  setActiveHoveringItem?: any
  activeId?: string | null
  setActiveElement?: any
  remutateSubmissionsWithStatus?: string[]
  setRemutateSubmissionsWithStatus?: any
  setActiveSubmissionId?: any
  setMainPostView?: any
  width?: number
  filterMode: string
  defaultFilters: ISubmissionFilters
  enableRedirection?: boolean
  isLast?: boolean
  moreThanTwo?: boolean
  index?: number
  setUrl?: any
  mainPostView?: boolean
  activeSubmissionId: string
}> = ({
  org,
  status,
  user,
  setActiveHoveringItem,
  activeId,
  setActiveElement,
  remutateSubmissionsWithStatus,
  setRemutateSubmissionsWithStatus,
  setActiveSubmissionId,
  setMainPostView,
  width = 0,
  filterMode,
  defaultFilters,
  enableRedirection,
  isLast,
  moreThanTwo,
  index,
  setUrl,
  mainPostView,
  activeSubmissionId,
}) => {
  const {
    submission: singleSubmission,
    mutateSingleSubmission,
    rawSubmissionData: rawSingleSubmissionData,
  } = useSingleSubmission(activeSubmissionId || '')

  const monthStyles: { [key: string]: string } = {
    jan: 'bg-fuchsia-100/50 dark:bg-fuchsia-500/10 dark:text-fuchsia-400 dark:border-fuchsia-500/10 text-fuchsia-600 border-fuchsia-100/50',
    feb: 'bg-purple-50 dark:bg-purple-500/10 dark:text-purple-400 dark:border-purple-500/10 text-purple-600 border-purple-100',
    mar: 'bg-violet-50 text-violet-600 dark:bg-violet-500/10 dark:text-violet-400 dark:border-violet-500/10 border-violet-100',
    apr: 'bg-blue-50 text-blue-600 dark:bg-blue-500/10 dark:text-blue-400 dark:border-blue-500/10 border-blue-100',
    may: 'bg-rose-50 dark:bg-rose-500/10 dark:text-rose-500 text-rose-600 dark:border-rose-500/10 border-rose-100',
    jun: 'bg-green-50 dark:bg-green-500/10 dark:text-green-500 text-green-600 dark:border-green-500/10 border-green-100',
    jul: 'bg-lime-50 dark:bg-lime-500/10 dark:text-lime-500 text-lime-600 dark:border-lime-500/10 border-lime-100',
    aug: 'bg-yellow-50 dark:bg-yellow-500/10 dark:text-yellow-500 text-yellow-600 dark:border-yellow-500/10 border-yellow-100',
    sep: 'bg-yellow-50 dark:bg-yellow-500/10 dark:text-yellow-500 text-yellow-600 dark:border-yellow-500/10 border-yellow-100',
    oct: 'bg-amber-50 dark:bg-amber-500/10 dark:text-amber-500 text-amber-600 dark:border-amber-500/10 border-amber-100',
    nov: 'bg-orange-50 dark:bg-orange-500/10 dark:text-orange-500 text-orange-600 dark:border-orange-500/10 border-orange-100',
    dec: 'bg-red-50 dark:bg-red-500/10 dark:text-red-500 text-red-600 dark:border-red-500/10 border-red-100',
  }

  const quarterStyles: { [key: string]: string } = {
    Q1: 'bg-purple-50 dark:bg-purple-500/10 dark:text-purple-400 dark:border-purple-500/10 text-purple-600 border-purple-100',

    Q2: 'bg-blue-50 text-blue-600 dark:bg-blue-500/10 dark:text-blue-400 dark:border-blue-500/10 border-blue-100',

    Q3: 'bg-lime-50 dark:bg-lime-500/10 dark:text-lime-500 text-lime-600 dark:border-lime-500/10 border-lime-100',

    Q4: 'bg-orange-50 dark:bg-orange-500/10 dark:text-orange-500 text-orange-600 dark:border-orange-500/10 border-orange-100',
  }

  const isStatusFilter = status?.name === 'non-all-222' ? false : true
  const [isFullRoadmap, setIsFullRoadmap] = useAtom(fullRoadmapAtom)
  const [customModal, setCustomModal] = useState<any>(null)
  const [createPost, setCreatePost] = useState(false)
  const [eta, setEta] = useState<Date | undefined>()

  const { isOver, setNodeRef } = useDroppable({
    id: status.id,
  })

  const { t } = useTranslation()
  const sortByNames = {
    [sortBy.top]: t('top-upvoted'),
    [sortBy.recent]: t('recent-posts'),
    [sortBy.trending]: t('trending-posts'),
    [sortBy.inReview]: t('pending-posts'),
    [sortBy.mrr]: t('upvoter-mrr'),
  }
  const mainFilters = generateRoadmapMainFilters(isStatusFilter, status.id)

  const [filters, setFilters] = useState<ISubmissionFilters>({
    ...defaultFilters,
    advancedFilters: [...defaultFilters.advancedFilters, ...mainFilters],
  })

  const stringifiedFilters = JSON.stringify(defaultFilters)

  useEffect(() => {
    if (isStatusFilter) {
      const newDefaultFilters: any = { ...defaultFilters }

      setFilters({
        ...newDefaultFilters,
        advancedFilters: [...newDefaultFilters.advancedFilters, ...mainFilters],
      })
    } else {
      const newDefaultFilters: any = { ...defaultFilters }

      setFilters({
        ...defaultFilters,
        advancedFilters: [...newDefaultFilters.advancedFilters, ...mainFilters],
      })
    }
  }, [stringifiedFilters, isStatusFilter])

  const [isLoading, setIsLoading] = useState(false)

  useDndMonitor({
    onDragEnd(event) {
      if (event.active?.id && event?.over) {
        setIsLoading(true)

        if (event.active.data?.current?.currentStautsId !== event.over.id) {
          handleStatusChange(event)
        }

        setIsLoading(false)
      }
    },
  })

  const handleStatusChange = (event: any) => {
    if (event.active.data.current.currentStautsId === status.id) {
      removeFromCurrentStatus(event)
    }

    if (event.over.id === status.id) {
      addToNewStatus(event)
      updateSubmission(event)
    }
  }

  const removeFromCurrentStatus = (event: any) => {
    if (rawSubmissionData) {
      mutateSubmissions(
        rawSubmissionData.map((entry) => ({
          ...entry,
          results: entry.results.filter((result) => result.id !== event.active.id),
        })),
        false
      )
    }
  }

  const addToNewStatus = (event: any) => {
    if (rawSubmissionData) {
      const newEntry = isStatusFilter
        ? { ...event.active.data.current.entry, postStatus: status }
        : { ...event.active.data.current.entry, eta: getEndDate(status.id) }

      mutateSubmissions(
        rawSubmissionData.map((entry) => ({
          ...entry,
          results: [newEntry, ...entry.results],
        })),
        false
      )
    }
  }

  const updateSubmission = (event: any) => {
    let updatedata: any = {
      submissionId: event.active.id as string,
      notifyVoters: false,
      eta: undefined,
      postStatus: undefined,
    }

    if (isStatusFilter) {
      updatedata.postStatus = status
    } else {
      updatedata.eta = getEndDate(event.over.id) // Convert to ISO string
    }

    updateSubmissionInfo(updatedata)
      .finally(() => {
        setIsLoading(false)
        handleCustomToast(event, updatedata)
        mutateSingleSubmission && mutateSingleSubmission()
      })
      .catch(() => {
        sonnerToast.error('Failed to update post.')
      })
  }

  const handleCustomToast = (event: any, updatedata: any) => {
    const submission = event.active.data.current.entry

    if (isStatusFilter && shouldDisplayToast(submission)) {
      displayToast(submission, updatedata)
    }
  }

  const shouldDisplayToast = (submission: any): boolean => {
    return submission ? (submission.upvoted && submission.upvotes === 1 ? false : true) : false
  }

  const displayToast = (submission: any, updatedata: any) => {
    toast.custom(
      (t) => (
        <Transition
          show={mainPostView ? false : t.visible}
          enter="ease-out opacity-0 duration-300"
          enterFrom="opacity-0"
          className="z-[20]"
          enterTo="opacity-100 "
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`z-[20] dropdown-background rounded-md backdrop-blur-lg max-w-sm w-[384px] shadow-lg dark:shadow-2xl dark:shadow-black/70`}
          >
            <MessageComponent
              emailSentCallback={() => mutateSingleSubmission()}
              activeTitle={submission?.title}
              setOpen={() => toast.dismiss(updatedata.submissionId)}
              fullWidth={true}
              submission={{ ...submission, postStatus: status } as ISubmission}
              setCustomModal={setCustomModal}
            />
          </div>
        </Transition>
      ),
      {
        id: updatedata.submissionId,
        position: 'top-center',
        duration: 15000,
      }
    )
  }

  const {
    submissionResults,
    mutateSubmissions,
    size,
    setSize,
    totalSubmissionResults,
    rawSubmissionData,
    submissionLoading,
  } = useSubmissionsWithFiltering(generateRoadmapFilter(filters), org, 1)

  useEffect(() => {
    mutateSubmissions()
  }, [])

  let showLoader = totalSubmissionResults ? size * 10 < totalSubmissionResults : false

  const actuallySubmission = submissionResults?.find((submission) => submission.id === activeId)

  useEffect(() => {
    if (activeId === actuallySubmission?.id && actuallySubmission) {
      setActiveElement(
        <RoadmapElement
          entry={actuallySubmission}
          mutateSubmissions={wrapperMutateSubmissions}
          rawSubmissionData={rawSubmissionData}
          setActiveSubmissionId={setActiveSubmissionId}
          setMainPostView={setMainPostView}
          filterMode={filterMode}
        />
      )
    }
  }, [activeId, setActiveElement, actuallySubmission])

  const debouncedSave = useCallback(
    debounce((newStatus) => {
      if (newStatus) {
        setIsLoading(true)
        mutateSubmissions()
          .then(() => setIsLoading(false))
          .catch(() => setIsLoading(false))

        // Dont remove all entries with the same status, just the one we are currently updating
        setRemutateSubmissionsWithStatus((prev: string[]) =>
          prev.filter((entry) => entry !== newStatus)
        )
      }
    }, 500),
    []
  )

  useEffect(() => {
    if (
      isStatusFilter
        ? remutateSubmissionsWithStatus?.includes(status.name)
        : remutateSubmissionsWithStatus?.includes(status.id) && !isLoading
    ) {
      debouncedSave(isStatusFilter ? status.name : status.id)
    }
    // cancel the debounce on useEffect cleanup
    return debouncedSave.cancel
  }, [isLoading, remutateSubmissionsWithStatus, debouncedSave])

  const getStyles = (status: any) => {
    if (isStatusFilter) {
      return statusColorData.find((color) => color?.name === status?.color)?.color
    } else {
      if (filterMode === 'quarterly') {
        return quarterStyles[status.id.substring(0, 2)]
      } else {
        return monthStyles[status.id.slice(0, -3)]
      }
    }
  }

  const wrapperMutateSubmissions = useCallback(
    async (...args: any[]) => {
      const result = await mutateSubmissions(...args)

      if (mutateSingleSubmission && result && singleSubmission) {
        // Map the result to the single submission
        let singleSubmission = null

        result?.forEach((result) => {
          result?.results?.forEach((submission: ISubmission) => {
            if (submission.id === activeSubmissionId) {
              singleSubmission = submission
            }
          })
        })

        if (singleSubmission) {
          mutateSingleSubmission(
            {
              results: [singleSubmission],
              page: 1,
              limit: 10,
              totalPages: 1,
              totalResults: 1,
            },
            false
          )
        }
      }
      return result
    },
    [
      mutateSubmissions,
      mutateSingleSubmission,
      submissionResults,
      activeSubmissionId,
      rawSingleSubmissionData,
    ]
  )

  return (
    <>
      <PopupWrapper
        hasPadding={false}
        fixToTop={true}
        isOpen={createPost}
        setIsOpen={setCreatePost}
        medium={true}
      >
        <CreatePost
          eta={eta}
          // @ts-ignore
          postStatus={isStatusFilter ? status : null}
          isOpen={createPost}
          mutateSubmissions={wrapperMutateSubmissions}
          setIsOpen={setCreatePost}
          rawSubmissionData={rawSubmissionData}
        />
      </PopupWrapper>
      {customModal}

      <div
        ref={setNodeRef}
        className={`relative pb-4 ${!isLast && 'md:mr-4 md:mb-0 mb-3'} w-full ${
          moreThanTwo ? 'md:min-w-[328px]' : ''
        }  md:flex-shrink-0  md:flex-1 h-full basis-full border-gray-100/50 dark:border-border/60`}
      >
        <div
          className="absolute top-0.5 bottom-0 right-0 -left-1.5 bg-gray-50/60 dark:bg-darker-bg/[13%] rounded-lg"
          // style={{
          //   // @ts-ignore
          //   border: status?.color
          //     ? `1px solid ${tagColorData.find(
          //         // @ts-ignore
          //         (color) => color?.name === status?.color
          //       )?.hex}09`
          //     : undefined,
          // }}
        ></div>
        {/* <div
          className="absolute  top-0.5 bottom-0 right-0 opacity-[15%] dark:opacity-[23%] -left-1.5 rounded-lg"
          style={{
            // @ts-ignore
            background: status?.color
              ? `linear-gradient(to bottom, ${tagColorData.find(
                  // @ts-ignore
                  (color) => color?.name === status?.color
                )?.hex}20 0%, transparent 550px)`
              : undefined,
          }}
        ></div> */}

        <div className="sticky top-0 z-10 flex items-center justify-between py-3 mx-1 backdrop-filter">
          <p
            className={`font-semibold ${getStyles(
              status
            )} pointer-events-none max-w-[265px]  px-2 py-1 rounded-md border flex text-xs items-center `}
          >
            <span className="truncate first-letter:uppercase">
              {isStatusFilter ? status.name : status.id}
            </span>
          </p>
          {user &&
            isMember(user.id, org) &&
            (can(user.id, 'set_post_status', org) ||
              org.postStatuses.find((s) => s.id === status.id && s.isDefault)) && (
              <button
                onClick={() => {
                  if (!isStatusFilter) {
                    setEta(retrieveDateWithoutTimezone(getEndDate(status.id)))
                  }
                  setCreatePost(true)
                }}
                className="p-0.5 white-btn border-none dark:text-foreground hover:bg-white dark:hover:bg-secondary bg-transparent   dark:bg-transparent shadow-none text-background-accent/60"
              >
                <PlusIcon className="w-4 h-4 secondary-svg" />
              </button>
            )}
        </div>
        <div className="relative">
          <AnimatePresence>
            {isOver && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.35,
                  ease: 'easeInOut',
                }}
                className="absolute -ml-1.5 inset-0 -mt-1 -mb-4 rounded-md z-50 flex flex-col p-4 backdrop-blur-[1px] items-center justify-center bg-white/50 border dark:border-secondary dark:bg-secondary/50"
              >
                <div className="flex flex-col items-center justify-center p-4 bg-white border rounded-md shadow-sm dark:bg-secondary dark:border-border dark:shadow">
                  <p className="text-sm font-semibold text-gray-600 dark:text-gray-50">
                    Drop here to move to this column
                  </p>
                  <p className="mt-2 text-xs text-gray-400 dark:text-foreground">
                    This list is ordered by{' '}
                    {filters.sortBy && sortByNames[filters.sortBy ? filters.sortBy : '']}
                  </p>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="">
            <AnimatePresence>
              <motion.div
                className={cn(
                  'relative custom-scrollbar',
                  activeId ? 'overflow-hidden' : ' sm:overflow-y-auto',
                  !isFullRoadmap ? 'sm:h-[60vh]' : 'sm:h-[82vh]'
                )}
              >
                {can(user?.id, 'set_post_status', org) ? (
                  <AnimatePresence initial={false}>
                    {submissionResults?.map((entry, index) => (
                      <DraggableElement
                        currentStatusId={status.id}
                        entry={entry}
                        id={entry.id}
                        key={entry.id}
                        org={org}
                        user={user}
                        width={width}
                        index={index}
                      >
                        <RoadmapElement
                          entry={entry}
                          enableRedirection={enableRedirection}
                          mutateSubmissions={wrapperMutateSubmissions}
                          rawSubmissionData={rawSubmissionData}
                          setActiveSubmissionId={setActiveSubmissionId}
                          setMainPostView={setMainPostView}
                          filterMode={filterMode}
                          setUrl={setUrl}
                        />
                      </DraggableElement>
                    ))}
                  </AnimatePresence>
                ) : (
                  submissionResults?.map((entry, index) => (
                    <RoadmapElement
                      entry={entry}
                      enableRedirection={enableRedirection}
                      mutateSubmissions={wrapperMutateSubmissions}
                      rawSubmissionData={rawSubmissionData}
                      setActiveSubmissionId={setActiveSubmissionId}
                      setMainPostView={setMainPostView}
                      filterMode={filterMode}
                      key={entry.id} // Don't forget to add a key when mapping directly
                      setUrl={setUrl}
                    />
                  ))
                )}

                <div className="mt-5 sm:mt-8">
                  {submissionLoading && (
                    <div className="pb-7">
                      <div className="w-6 h-6 mx-auto secondary-svg">
                        <Loader />
                      </div>
                    </div>
                  )}
                  <div className="sm:hidden">
                    {!submissionLoading && showLoader && (
                      <div className="px-1 -mt-3">
                        <button
                          className="dashboard-secondary"
                          onClick={() => {
                            setSize(size + 1)
                          }}
                        >
                          Load more
                        </button>
                      </div>
                    )}{' '}
                  </div>
                  <div className="hidden sm:block">
                    {!submissionLoading && showLoader && !isLoading && (
                      <div>
                        <InView
                          as="div"
                          onChange={(inView: boolean) => {
                            inView && setSize(size + 1)
                          }}
                        >
                          <div className="flex items-center justify-center mt-4 text-gray-200 dark:text-foreground/60 pb-7">
                            <div className="w-6 h-6 secondary-svg">
                              <Loader />
                            </div>
                          </div>
                        </InView>
                      </div>
                    )}
                  </div>
                  {submissionResults?.length === 0 && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="flex flex-col items-center justify-center pt-6 pb-4 text-sm font-medium text-center text-background-accent sm:pt-20 sm:pb-10 dark:text-foreground"
                    >
                      <div className="w-16 h-16 mx-auto mb-4">
                        <EmptyIllustration tailwind={getStyles(status)} />
                      </div>
                      <p>
                        {isStatusFilter ? (
                          <Trans
                            i18nKey="no-status-posts"
                            components={[<span className="capitalize" key="nostatusposts" />]}
                            values={{ status: status.name }}
                          ></Trans>
                        ) : (
                          <Trans
                            i18nKey="nothing-scheduled-for-quarter"
                            components={[<span className="capitalize" key="noquarterposts" />]}
                            values={{ date: status.id }}
                          ></Trans>
                        )}
                      </p>
                    </motion.div>
                  )}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </>
  )
}

export default DedicatedRoadmapField
