import { uploadImageCloudflare } from 'network/lib/organization'
import { toast } from 'sonner'
import { createImageUpload } from './extensions/HandleNonCommandImage/regular-upload-images'

const onUpload = (file: File) => {
  // Create a new FormData object and append the file
  const formData = new FormData()
  formData.append('image', file)

  // Start uploading using the Cloudflare upload function
  const promise = uploadImageCloudflare(formData)

  return new Promise((resolve, reject) => {
    toast.promise(
      promise
        .then((res) => {
          if (res.status === 200) {
            const { src } = res.data
            if (file.type.startsWith('image/')) {
              // Handle image files
              let image = new Image()
              image.src = src
              image.onload = () => {
                resolve(src)
              }
            } else if (file.type === 'video/mp4') {
              // Handle MP4 video files
              resolve(src)
            }
          } else {
            throw new Error('Error uploading file. Please try again.')
          }
        })
        .catch((err) => {
          toast.error(err.response?.data.error)
          reject(err.response?.data.error)
        }),
      {
        loading: 'Uploading file...',
        success: 'File uploaded successfully.',
        error: (e) => e.message,
      }
    )
  })
}
export const uploadFn = createImageUpload({
  onUpload,
  validateFn: (file: any) => {
    if (!file.type.startsWith('image/') && file.type !== 'video/mp4') {
      toast.error('File type not supported. Please upload an image or MP4 video.')
      return false
    } else if (file.type.startsWith('image/') && file.size / 1024 / 1024 > 20) {
      toast.error('Image file size too big (max 20MB).')
      return false
    } else if (file.type === 'video/mp4' && file.size / 1024 / 1024 > 80) {
      toast.error('Video file size too big (max 80MB).')
      return false
    }
    return true
  },
})
