import { IJiraSyncRule } from '../../src/data/organization'
import { Tag } from '../../src/components/NewEditModal'
import {
  CustomInputField,
  IChatQueryResult,
  IClickupAutomaticPushRules,
  IClickupSyncRule,
  IGithubSyncRule,
  IGithubAutomaticPushRules,
  ILinearSyncRule,
  IMemberRole,
  IOrganization,
  IOrganizationStatus,
  IWidget,
  UserSegment,
} from '../../src/interfaces/IOrganization'
import axiosClient from '../apiClient'

import { IPostCategory, IPostCategoryWithoutId } from '@/interfaces/ISubmission'

export type IOrganizationUpdate = {
  newDisplayName?: string
  newOrganization?: string
  anyoneCanUpvote?: boolean
  anyoneCanSubmit?: boolean
  anyoneCanComment?: boolean
  hideChangelog?: boolean
  hideRoadmap?: boolean
  hideFeedbackBoard?: boolean
  ogImage?: string
  downvotesEnabled?: boolean
  newBrandColor?: string
  customDomain?: string
  hasPublicTags?: boolean
  hiddenCategories?: string[]
  private?: boolean
  segmentWriteKey?: string
  CTASection?: {
    title: string
    content: string
    buttonText: string
  }
  notifications?: {
    notifyAllComments?: boolean
  }
  mainFeedbackButtonText?: string
  widget?: IWidget
  ssoUrl?: string
  steamAuthEnabled?: boolean
  discordAuthEnabled?: boolean
  postModerationEnabled?: boolean
  statuses?: string[]
  postStatuses?: IOrganizationStatus[]
  setupSteps?: IOrganization['setupSteps']
  defaultSortingOrder?: string
  defaultTimeframe?: string
  defaultTheme?: string
  linearSyncRules?: ILinearSyncRule[]
  clickupSyncRules?: IClickupSyncRule[]
  githubSyncRules?: IGithubSyncRule[]
  jiraSyncRules?: IJiraSyncRule[]
  linearTeamId?: string
  clickupTeamId?: string
  onLinearDelete?: 'keep' | 'delete'
  onJiraDelete?: 'keep' | 'delete'
  onClickupDelete?: 'keep' | 'delete'
  slackChannelId?: string
  jiraProjectId?: string
  jiraIssueTypeId?: string
  changelogLocales?: {
    locale: string
    default: boolean
  }[]
  hideCompletedAndCanceled?: boolean
  hideVoteCountUntilVoted?: boolean
  hideStatusFromPublic?: boolean
  mainChangelogButtonText?: string
  clickupPreviousTeamId?: string
  clickupPreviousFolderId?: string
  clickupPreviousListId?: string
  clickupAutomaticPushRules?: IClickupAutomaticPushRules
  githubPreviouslySelectedRepoId?: string
  githubAutomaticPushRules?: IGithubAutomaticPushRules
  emailReplyTo?: string
  sendWeeklyUserReport?: boolean
  discordWebhookUrl?: string
  onlyStatusRoadmap?: boolean
  hideAuthorInfo?: boolean
  intercom?: {
    title: string
    description: string
    buttonText: string
  }
  privateOrgNonAdminSsoAccess?: boolean
  valueEffortScale?: string
  roadmapStatuses?: string[]
  customInputFields?: CustomInputField[]
  customTheme?: {
    graySaturation: number
    graySaturator: string
    enabled: boolean
  }
  autopushPostsToLinear?: boolean
  memberRoles?: IMemberRole[]
  githubDefaultRepoId?: string
  activeHelpCenter?: {
    enabled: boolean
    title: string
    helpCenterId: string
  }
  newSetupSteps?: IOrganization['newSetupSteps']
}

export function organizationExists(data: { organization: string }) {
  return axiosClient.get('/v1/organization/exists', { params: { ...data } })
}

export function getOrganization() {
  return axiosClient.get('/v1/organization')
}

export function updateSegment(segment: UserSegment) {
  return axiosClient.patch('/v1/organization/segment', { segment })
}

export function deleteSegment(segmentId: string) {
  return axiosClient.delete('/v1/organization/segment', { data: { segmentId } })
}

export function createSegment(segment: Omit<UserSegment, '_id'>) {
  return axiosClient.post('/v1/organization/segment', { segment })
}

export function createOrganization(data: {
  name?: string
  displayName?: string
  color: string
  language?: string
  private?: boolean
  onboardingData?: any
}) {
  return axiosClient.post('/v1/organization', data)
}

export function addTeamMember(emails: string[], roleId?: string) {
  return axiosClient.post('/v1/organization/admin', { emails, roleId })
}

export function setTeamMemberRole(memberId: string, roleId: string) {
  return axiosClient.post('/v1/organization/member/role', { memberId, roleId })
}

export function updateUserSegment(segments: any) {
  return axiosClient.put('/v1/organization/userSegment', { segments })
}

export function addUserToRole(data: { emails: string[]; roles: string[] }) {
  return axiosClient.post('/v1/organization/roles/multiple', data)
}

export function checkCoupon(coupon: string) {
  return axiosClient.post('/v1/organization/coupon', { coupon })
}

export function removeTeamMember(adminToRemove: string) {
  return axiosClient.delete('/v1/organization/admin', { data: { adminToRemove } })
}

export function promoteToOwner(adminToPromote: string) {
  return axiosClient.post('/v1/organization/promote', { adminId: adminToPromote })
}

export function removeInvite(email: string) {
  return axiosClient.delete('/v1/organization/invites', { data: { inviteToRemove: email } })
}

export function getZendeskConfig() {
  return axiosClient.get(`/v1/zendesk/config`)
}

export function uninstallZendesk() {
  return axiosClient.post(`/v1/zendesk/uninstall`)
}

export function getClickupPushRules() {
  return axiosClient.get(`/v1/organization/clickup/automaticpushrules`)
}
export function getClickupSyncRules() {
  return axiosClient.get(`/v1/organization/clickup/syncrules`)
}

export function getGithubPushRules() {
  return axiosClient.get(`/v1/organization/github/automaticpushrules`)
}

export function getGithubSyncRules() {
  return axiosClient.get(`/v1/organization/github/syncrules`)
}

export function removeTrackedUser(email: string) {
  return axiosClient.delete('/v1/organization/trackedUsers', { data: { email } })
}

export function askAIBot(data: { text: string; organizationId: string }) {
  return axiosClient.post('/v1/organization/aihelp', data)
}

export function getDiscordSecret() {
  return axiosClient.get('/v1/organization/discordSecret')
}

export function getAlgoliaKey() {
  return axiosClient.get('/v1/organization/algoliaSecret')
}

export function getHelpCenterSearchKey() {
  return axiosClient.get('/v1/helpcenter/search/apiKey')
}

export interface IChatlogResult {
  success: boolean
  results: IChatQueryResult[]
}

export function getChatlogs(orgId: string) {
  return axiosClient.get('/v1/organization/aihelp?limit=50&organizationId=' + orgId)
}

export function getExport() {
  return axiosClient.get('/v1/submission/export/csv')
}
export function getExportStatus({ filename }: { filename: string }) {
  return axiosClient.get('/v1/submission/export/status', { params: { filename } })
}

export function getCustomDomainDetails() {
  return axiosClient.get('/v1/organization/getCustomDomainDetails')
}
export function getHelpCenterCustomDomainDetails() {
  return axiosClient.get('/v1/helpcenter/domain')
}
export function getFilters() {
  return axiosClient.get('/v1/organization/filters')
}
export function createFilter(data: {
  title: string
  description: string
  isPrivate: boolean
  filter: string
}) {
  return axiosClient.post('/v1/organization/filters', { ...data })
}

export function updateFilter(data: {
  id: string
  title: string
  description: string
  isPrivate: boolean
  filter: string
}) {
  return axiosClient.patch('/v1/organization/filters', { ...data })
}

export const deleteFilter = (id: string) => {
  return axiosClient.delete('/v1/organization/filters', { data: { id } })
}

export function acceptInvite(data: { token: string; organization: string }) {
  return axiosClient.post('/v1/organization/acceptInvite', data)
}

export function updateOrganization(data: IOrganizationUpdate) {
  return axiosClient.patch('/v1/organization', data)
}

export function addNewCategory(data: IPostCategoryWithoutId) {
  return axiosClient.post('/v1/organization/category', data)
}

export function updateOrgPicture(data: FormData) {
  return axiosClient.post('/v1/organization/image', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function changeSubdomain(data: { subdomain: string }) {
  return axiosClient.post('/v1/organization/subdomain', data)
}

export function deleteOrganization() {
  return axiosClient.delete('/v1/organization')
}

export function updateEmailBrandingPicture(data: FormData) {
  return axiosClient.post('/v1/organization/emailPicture', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function uploadScreenshot(data: FormData) {
  return axiosClient.post('/v1/organization/upload/screenshot', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function uploadImageCloudflare(data: FormData) {
  return axiosClient.post('/v1/organization/upload/cloudflare', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export function updateCategory(data: {
  oldCategory: string
  newCategory: string
  private?: boolean
  roles?: string[]
  prefill?: string
  hiddenFromRoles?: string[]
  disablePostCreation?: boolean
  disableFollowUpQuestions?: boolean
  customInputFields?: string[]
  defaultAuthorOnly?: boolean
  hideCreationDates?: boolean
}) {
  return axiosClient.patch('/v1/organization/category', data)
}

export function deleteCategory(category: string) {
  return axiosClient.delete('/v1/organization/category', { data: { category } })
}

export function createRole(role: string) {
  return axiosClient.post('/v1/organization/roles', { role })
}

export function removeNotification(id: string) {
  return axiosClient.post('/v1/user/notification/viewSubmission', { submissionId: id })
}

export function removeNotificationById(id: string) {
  return axiosClient.post('/v1/user/clearNotification', { id })
}

export function createMicrosoftAccount(
  jwt: string,
  email: string,
  name: string,
  adminInviteToken?: string
) {
  return axiosClient.post(
    '/v1/auth/microsoft/createAccount',
    adminInviteToken ? { token: jwt, email, name, adminInviteToken } : { token: jwt, email, name }
  )
}

export function importCSV(formData: FormData) {
  return axiosClient.post('/v1/organization/import/csv', formData)
}

export function createChangelogCategory(category: {
  name: string
  roles?: string[]
  color: string
}) {
  return axiosClient.post('/v1/changelog/categories', {
    name: category.name,
    roles: category.roles,
    color: category.color,
  })
}

export function getCSRFToken() {
  return axiosClient.get('/v1/organization/csrf')
}

export function deleteChangelogCategory(category: IOrganization['changelogCategories'][0]) {
  return axiosClient.delete('/v1/changelog/categories', { data: { id: category.id } })
}

export function updateChangelogCategory(category: IOrganization['changelogCategories'][0]) {
  return axiosClient.patch('/v1/changelog/categories', { ...category })
}

export function createTag(tag: Tag) {
  return axiosClient.post('/v1/organization/tag', {
    name: tag.name,
    private: tag.private,
    color: tag.color,
  })
}

export function createTrial({ plan }: { plan?: string }) {
  return axiosClient.post('/v1/organization/trial', { plan })
}

export function downgradeToFree() {
  return axiosClient.post('/v1/organization/downgrade')
}

export function deleteTag(postTag: Tag) {
  return axiosClient.delete('/v1/organization/tag', { data: { id: postTag?.id } })
}

export function addExtraAdminSubscription({ adminCount }: { adminCount: number }) {
  return axiosClient.post('/v1/organization/addExtraAdminSubscription', { adminCount })
}

export function updateTag(postTag: { id: string; name: string; private: boolean; color: string }) {
  return axiosClient.patch('/v1/organization/tag', { ...postTag })
}

export function deleteRole(role: string) {
  return axiosClient.delete('/v1/organization/roles', { data: { role } })
}

export function updateRole(oldRole: string, newRole: string) {
  return axiosClient.patch('/v1/organization/roles', { oldRole, newRole })
}

export function addStatus(postStatus: IOrganizationStatus) {
  return axiosClient.post('/v1/organization/statuses', { postStatus })
}

export const sendSurvey = (survey: {
  submissionId: string
  type: 'often' | 'importance' | 'urgency'
  score: number
}): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    axiosClient
      .post('/v1/organization/survey', { ...survey })
      .then((res) => {
        if (res?.data?.success) {
          resolve()
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function rawStatuses(statuses: IOrganizationStatus[]) {
  return axiosClient.post('/v1/organization/statuses/updateRaw', { statuses })
}

export function rawTags(postTags: { id: string; name: string; private: boolean; color: string }[]) {
  return axiosClient.post('/v1/organization/tag/updateRaw', {
    tags: postTags,
  })
}

export function rawCategories(postCategories: IPostCategory[]) {
  return axiosClient.post('/v1/organization/category/updateRaw', { postCategories })
}

export function deleteStatus(postStatus: IOrganizationStatus | undefined) {
  return axiosClient.delete('/v1/organization/statuses', { data: { postStatus } })
}

export function updateStatus(
  oldStatus: IOrganizationStatus | undefined,
  newStatus: IOrganizationStatus | undefined
) {
  return axiosClient.patch('/v1/organization/statuses', { oldStatus, newStatus })
}

export function stripePortalUrl(return_to: string) {
  return axiosClient.get('/v1/webhook/stripePortal', { params: { return_to } })
}

export function startCheckout(
  plan: 'growth' | 'starter' | 'business',
  duration: 'monthly' | 'yearly' | 'lifetime',
  referralId?: string
) {
  return axiosClient.get('/v1/webhook/', { params: { plan, duration } })
}

export function startSubscriptionChangePortalSession(return_to: string) {
  return axiosClient.get('/v1/webhook/updateSubscription', { params: { return_to } })
}

export function getEnabledIntegrations() {
  return axiosClient.get('/v1/organization/enabledIntegrations')
}

export function cancelSubscription() {
  return axiosClient.post('/v1/webhook/cancelSubscription')
}

/**
 * Zapier API Key
 * @returns Zapier API Key
 */
export function getApiKey() {
  return axiosClient.get('/v1/organization/apiKey')
}

/**
 * Featurebase API Key
 * @returns Zapier API Key
 */
export function getFeaturebaseApiKey() {
  return axiosClient.get('/v1/organization/apiSecret')
}

export function addAllowedDomains({ domains }: { domains: string[] }) {
  return axiosClient.post('/v1/organization/allowedDomains', { domains })
}

export function deleteAllowedDomain({ domain }: { domain: string }) {
  return axiosClient.delete('/v1/organization/allowedDomains', { data: { domain } })
}

export function getJwtSecret() {
  return axiosClient.get('/v1/organization/jwtSecret')
}

export function getJwtSecretForOrganization(organization: string) {
  return axiosClient.get('/v1/organization/jwtSecret', {
    params: { organization },
    baseURL:
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
        ? `http://${organization}.localhost:3000/api`
        : `https://${organization}.featurebase.app/api`,
  })
}

export function verifyJWTValidity({ organization, jwt }: { jwt: string; organization: string }) {
  return axiosClient.post(
    '/v1/organization/verifyJWT',
    { jwt, organization },
    {
      baseURL:
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
          ? `http://developers.localhost:5000/api`
          : `https://developers.featurebase.app/api`,
    }
  )
}

export function verifyJwt({ jwt }: { jwt: string }) {
  return axiosClient.post('/v1/organization/verifyJWT', { jwt })
}

export function uninstallIntercom() {
  return axiosClient.post('/v1/intercom/uninstall')
}
export function uninstallMicrosoft() {
  return axiosClient.post('/v1/auth/microsoft/uninstall')
}
export function linkToMicrosoft(jwt: string) {
  return axiosClient.post('/v1/auth/microsoft/link', { token: jwt })
}
export function uninstallClickup() {
  return axiosClient.post('/v1/organization/clickup/uninstall')
}

export function uninstallGithub() {
  return axiosClient.post('/v1/organization/github/uninstall')
}

export function uninstallLinear() {
  return axiosClient.post('/v1/organization/linear/uninstall')
}
export function uninstallJira() {
  return axiosClient.post('/v1/organization/jira/uninstall')
}

export function unlinkIssue(data: { submissionId: string }) {
  return axiosClient.post('/v1/organization/linear/unlink', { ...data })
}
export function unlinkClickup(data: { submissionId: string }) {
  return axiosClient.post('/v1/organization/clickup/unlink', { ...data })
}

export function unlinkJiraIssue(data: { submissionId: string }) {
  return axiosClient.post('/v1/organization/jira/unlink', { ...data })
}

export function uninstallSlack() {
  return axiosClient.post('/v1/slack/uninstall')
}

export function pushIssueToJira(
  title: string,
  description: string,
  submissionId: string,
  projectId?: string,
  issueTypeId?: string
) {
  return axiosClient.post('/v1/organization/jira/push', {
    title,
    description,
    submissionId,
    projectId,
    issueTypeId,
  })
}
export function pushIssueToClickup(d: {
  title: string
  description: string
  teamId: string
  submissionId: string
  status: string
  listId: string | undefined
}) {
  return axiosClient.post('/v1/organization/clickup', {
    title: d.title,
    description: d.description,
    teamId: d.teamId,
    submissionId: d.submissionId,
    status: d.status,
    listId: d.listId,
  })
}

export function pushIssueToGithub(d: {
  title: string
  description: string
  repoId: string
  submissionId: string
}) {
  return axiosClient.post('/v1/organization/github', {
    title: d.title,
    description: d.description,
    submissionId: d.submissionId,
    repoId: d.repoId,
  })
}

export function pushIssueToLinear(
  title: string,
  description: string,
  teamId: string,
  userId: string,
  submissionId: string,
  status: string
) {
  return axiosClient.post('/v1/organization/linear', {
    title,
    description,
    teamId,
    userId,
    submissionId,
    status,
  })
}

export function getCompanyById(id: string) {
  return axiosClient.get('/v1/organization/company', { params: { id } })
}

export function searchCompanies(
  query: string,
  options: {
    limit?: number
    page?: number
  } = {}
) {
  return axiosClient.get('/v1/organization/company/search', { params: { q: query, ...options } })
}
export function searchJiraIssues(query: string) {
  return axiosClient.get('/v1/organization/jira/search', { params: { q: query } })
}

export function searchLinearIssues(query: string) {
  return axiosClient.get('/v1/organization/linear/search', { params: { q: query } })
}
export function searchClickupTasks(query: string) {
  return axiosClient.get('/v1/organization/clickup/search', { params: { q: query } })
}

export function searchGithubIssues(query: string, submissionId: string) {
  return axiosClient.get('/v1/organization/github/search', { params: { q: query, submissionId } })
}

export function linkLinearIssue(
  submissionId: string,
  issueId: string,
  issueType: 'project' | 'issue'
) {
  return axiosClient.post('/v1/organization/linear/link', {
    submissionId,
    issueId,
    type: issueType,
  })
}

export function linkClickupIssue(submissionId: string, taskId: string) {
  return axiosClient.post('/v1/organization/clickup/link', { submissionId, taskId })
}

export function linkGithubIssue(submissionId: string, issueNumber: string, repoFullName: string) {
  return axiosClient.post('/v1/organization/github/link', {
    submissionId,
    issueNumber,
    repoFullName,
  })
}

export function linkJiraIssue(submissionId: string, issueId: string) {
  return axiosClient.post('/v1/organization/jira/link', { submissionId, issueId })
}

export function connectGithubApp(installationId: string, organizationId: string) {
  return axiosClient.post('/v1/auth/github_app/connect', {
    installationId,
    organizationId,
  })
}
