import { useEffect, useState } from 'react'
import { useCurrentOrganization } from '../data/organization'
import { ISubmission, ISubmissionFilters } from '../interfaces/ISubmission'
import PublicBoardWrapper from './PublicBoardWrapper'
import { sortBy } from './PopularitySorter'
import { InView } from 'react-intersection-observer'
import Loader from './Loader'
import { useUser } from '../data/user'
import { useSingleSubmission, useSubmissionsWithFiltering } from '../data/submission'
import EmptyFeedbackBoard from './EmptyFeedbackBoard'
import { getDefaultFilters } from '@/lib/utils'
import CreatePostPublicBoard from './CreatePostPublicBoard'
import PublicBoardFilters from './PublicBoardFilters'
import PublicSubmissionResult from './PublicSubmissionResult'
import FilterSyncer from './FilterSyncer'
import { useAtom } from 'jotai'
import MainPostViewPopup from './MainPostViewPopup'
import { submissionActiveFilterURIAtom } from '@/atoms/submissionAtom'
import FloatingCreatePostCTA from './FloatingCreatePostCTA'
import ChooseBoardsMenu from './ChooseBoardsMenu'
import useSubmissionUrl from '@/hooks/submissionUrlSyncer'
import PrivateRoleOrg from './PrivateRoleOrg'
import { AnimatePresence } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { isMember } from '@/lib/acl'

const FeedbackBoard: React.FC<{
  initialFilters?: ISubmissionFilters
  renderDate?: Date
  timezone?: string
}> = ({ initialFilters, renderDate, timezone }) => {
  const [activeSubmissionId, setActiveSubmissionId] = useState('')
  const [mainPostView, setMainPostView] = useState(false)
  const { org } = useCurrentOrganization()
  const { user } = useUser()
  const [activeFilterURI, setActiveFilterURI] = useAtom(submissionActiveFilterURIAtom)
  const { restoreUrl, setUrl } = useSubmissionUrl(true)
  const [filters, setFilters] = useState<ISubmissionFilters>(
    initialFilters || getDefaultFilters(org)
  )

  const [ref, inView] = useInView({
    threshold: 0, // Adjust this value as needed
  })

  const includePinned = !filters.advancedFilters?.some((item) => item.type === 'b')
    ? sortBy?.[org?.settings?.defaultSortingOrder] === filters.sortBy
      ? true
      : false
    : false

  const {
    submissionResults,
    mutateSubmissions,
    size,
    setSize,
    totalSubmissionResults,
    rawSubmissionData,
    submissionLoading,
  } = useSubmissionsWithFiltering(activeFilterURI, org)

  const {
    submission,
    mutateSingleSubmission,
    rawSubmissionData: rawSingleSubmissionData,
  } = useSingleSubmission(activeSubmissionId)

  useEffect(() => {
    if (user) {
      mutateSubmissions()
    }
  }, [mutateSubmissions, user])

  let showLoader = totalSubmissionResults ? size * 10 < totalSubmissionResults : false

  const activeSubmission = submissionResults?.find(
    (submission: ISubmission) => submission.id === activeSubmissionId
  )

  const onlyBoardFilters = filters?.advancedFilters?.filter((filter) => filter?.type === 'b')

  const currentActiveBoard =
    onlyBoardFilters?.length === 1
      ? onlyBoardFilters[0]?.values?.length === 1
        ? onlyBoardFilters[0]?.values[0]
        : undefined
      : undefined

  const activeBoard = org?.postCategories?.find((board) => board?.id === currentActiveBoard)

  const hidePostCreation =
    activeBoard?.disablePostCreation && !isMember(user?.id, org) ? true : false

  return (
    <PublicBoardWrapper
      setActiveSubmissionId={setActiveSubmissionId}
      filters={filters}
      setFilters={setFilters}
      mutateSubmissions={mutateSubmissions}
      rawSubmissionData={rawSubmissionData}
      submissionResults={submissionResults}
    >
      <div className="mt-4 main-board-layout">
        {org?.postCategories?.length === 0 && !isMember(user?.id, org) && org?.roles?.length > 0 ? (
          <div className={`relative min-h-[70vh] md:mt-0`}>
            <PrivateRoleOrg />
          </div>
        ) : (
          <div className={`relative min-h-[70vh] md:mt-0`}>
            <div ref={ref}>
              <CreatePostPublicBoard hidePostCreation={hidePostCreation} />
            </div>
            <PublicBoardFilters
              filters={filters}
              setFilters={setFilters}
              setMainPostView={setMainPostView}
              setActiveSubmissionId={setActiveSubmissionId}
              mutateAllSubmissions={() => {
                mutateSingleSubmission()
                mutateSubmissions()
              }}
              disableKeybind={mainPostView}
              hidePostCreation={hidePostCreation}
            />
            <FilterSyncer
              filters={{ ...filters, includePinned: includePinned } as ISubmissionFilters}
              setFilters={setFilters}
              setActiveFilterURI={setActiveFilterURI}
              defaultFilters={getDefaultFilters(org, includePinned)}
              hideCompletedAndCancelled={org.settings.hideCompletedAndCanceled}
            />
            <AnimatePresence>
              {!submissionLoading && (
                <div className="mt-4 -mx-4 overflow-hidden rounded-none border-x-0 sm:border-x sm:rounded-lg sm:mx-0 up-element dark:bg-secondary/80 border-y">
                  <div className="w-full divide-y dark:divide-border divide-gray-100/60">
                    {submissionResults &&
                      submissionResults?.map((submission) => (
                        <PublicSubmissionResult
                          setUrl={setUrl}
                          setMainPostView={setMainPostView}
                          setActiveSubmissionId={setActiveSubmissionId}
                          key={submission?.id}
                          filters={filters}
                          mutateSubmissions={mutateSubmissions}
                          rawSubmissionData={rawSubmissionData}
                          renderDate={renderDate}
                          submission={submission}
                          timezone={timezone}
                        />
                      ))}
                    {submissionResults?.length === 0 && (
                      <div className="px-4 pb-16 mx-auto">
                        <EmptyFeedbackBoard hidePostCreation={hidePostCreation} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </AnimatePresence>
            <div className="mt-16">
              {submissionLoading && (
                <div className="flex items-center justify-center mt-4 pb-7">
                  <div className="w-6 h-6 secondary-svg ">
                    <Loader />
                  </div>
                </div>
              )}

              {!submissionLoading && showLoader && (
                <InView
                  as="div"
                  onChange={(inView: boolean) => {
                    inView && setSize(size + 1)
                  }}
                >
                  <div className="flex items-center justify-center mt-4 pb-7">
                    <div className="w-6 h-6 secondary-svg ">
                      <Loader />
                    </div>
                  </div>
                </InView>
              )}
            </div>
          </div>
        )}

        <ChooseBoardsMenu
          setActiveSubmissionId={setActiveSubmissionId}
          setMainPostView={setMainPostView}
          setFilters={setFilters}
          filters={filters}
          setUrl={setUrl}
        />
      </div>
      {hidePostCreation ? null : <FloatingCreatePostCTA inView={inView} />}
      <MainPostViewPopup
        activeSubmissionId={activeSubmissionId}
        setShowPostView={() => {
          restoreUrl()
          setMainPostView(false)
          setActiveSubmissionId('')
        }}
        setActiveSubmissionId={setActiveSubmissionId}
        showPostView={mainPostView}
        activeSubmission={activeSubmission}
        mutateSubmissions={mutateSubmissions}
        submission={submission}
        rawSubmissionData={rawSubmissionData}
        rawSingleSubmissionData={rawSingleSubmissionData}
        mutateSingleSubmission={mutateSingleSubmission}
      />
    </PublicBoardWrapper>
  )
}

export default FeedbackBoard
