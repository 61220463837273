import {
  chatWithAIAtom,
  chatWithAIInputAtom,
  chatWithAIResponseAtom,
  loadingAIResponseAtom,
} from '@/atoms/orgAtom'
import { useUser } from '@/data/user'
import { ChevronRightIcon, XIcon } from '@heroicons/react/solid'
import { AnimatePresence, motion } from 'framer-motion'
import { useAtom } from 'jotai'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from './radix/Button'
import { useIntercom } from 'react-use-intercom'
import { useCurrentOrganization, useUserOrganizations } from '@/data/organization'
import { ContentModifier } from './ContentReplacer'
import { v4 as uuid } from 'uuid'
import Loader from './Loader'
import { cn } from '@/lib/utils'
import { askAIBot, getChatlogs, IChatlogResult } from 'network/lib/organization'
import { toast } from 'sonner'
import { IChatResult, IOrganization } from '@/interfaces/IOrganization'

export const defaultChat = {
  role: 'assistant',
  html: `<b className="font-semibold">Hey REPLACE_WITH_USER_NAME! 👋</b> Feel free
to ask me anything about Featurebase. I'm here to help!`,
  cursor: uuid(),
}

const FBAIBot: React.FC<{ customOrg?: IOrganization }> = ({ customOrg }) => {
  const [chatWithAI, setChatWithAI] = useAtom(chatWithAIAtom)
  const { user } = useUser()
  const { show } = useIntercom()
  const [textInput, setTextInput] = useAtom(chatWithAIInputAtom)
  const [loading, setLoading] = useAtom(loadingAIResponseAtom)
  const { mutateCurrentOrg, org: currentOrg } = useCurrentOrganization()
  const [results, setResults] = useAtom(chatWithAIResponseAtom)
  const [gettingData, setGettingData] = useState(false)
  const [fetchedForOrg, setFetchedForOrg] = useState('')

  const org = customOrg || currentOrg

  useEffect(() => {
    if (!org?.id) return
    if (fetchedForOrg === org?.id) return
    setFetchedForOrg(org?.id)
    getChatlogs(org?.id)
      .then((response) => {
        const data = response.data as IChatlogResult
        if (data.success) {
          setResults([...data.results, defaultChat])
        }
      })
      .catch((error) => {
        toast.error(
          'An error occurred while fetching chat logs, please refresh the page to try again'
        )
      })
  }, [org?.id, fetchedForOrg])

  const textareaRef = useRef(null)

  const adjustHeight = (element: any) => {
    element.style.height = 'inherit' // Reset the height to recalculate
    element.style.height = `${element.scrollHeight}px` // Set the height to scroll height
  }

  useEffect(() => {
    if (textareaRef.current) {
      adjustHeight(textareaRef.current)
    }
  }, [])

  useEffect(() => {
    // Autofocus on the textarea when chatWithAI is true
    if (chatWithAI) {
      if (textareaRef.current) {
        // @ts-ignore
        textareaRef.current?.focus()
      }
    }
  }, [chatWithAI])

  const handleInputChange = (event: any) => {
    adjustHeight(event.target)
    setTextInput(event.target.value)
  }

  const submitMessage = () => {
    if (!textInput.trim()) return // Prevent empty submissions
    if (loading) return // Prevent multiple submissions
    const newMessage = {
      type: 'chat',
      html: textInput,
      cursor: uuid(),
      role: 'user',
    }
    const loadingId = uuid()
    const aiAnswer = {
      type: 'chat',
      html: 'loading',
      cursor: loadingId,
      role: 'assistant',
    }
    setLoading(loadingId)

    const newResults = [aiAnswer, newMessage, ...results]

    setResults(newResults)

    setTextInput('')

    // Set ref height to 52
    if (textareaRef.current) {
      // @ts-ignore
      textareaRef.current.style.height = '52px'
    }
    askAIBot({ text: textInput, organizationId: org?.id })
      .then((response) => {
        const data = response.data as IChatResult

        if (data.success) {
          if (data.type === 'chat') {
            setResults(
              newResults.map((chat) =>
                chat.cursor === loadingId
                  ? {
                      ...chat,
                      html: data.html,
                    }
                  : chat
              )
            )
          } else if (data.type === 'action') {
            switch (data.action) {
              case 'trial_extended':
                setResults(
                  newResults.map((chat) =>
                    chat.cursor === loadingId
                      ? {
                          ...chat,
                          html: data.html,
                        }
                      : chat
                  )
                )
                mutateCurrentOrg && mutateCurrentOrg()
                break

              case 'suggest_feedback_widget':
                setResults(
                  newResults.map((chat) =>
                    chat.cursor === loadingId
                      ? {
                          ...chat,
                          html: data.html,
                        }
                      : chat
                  )
                )

                break
              case 'open_live_chat':
                setResults(
                  newResults.map((chat) =>
                    chat.cursor === loadingId
                      ? {
                          ...chat,
                          html: data.html,
                        }
                      : chat
                  )
                )

                setTimeout(() => {
                  setChatWithAI(false)
                  show()
                  //   @ts-ignore
                  window?.Intercom('showNewMessage', newMessage.html)
                }, 3000)
                break
              default:
                break
            }
          }
        }

        setLoading('')
      })
      .catch((error) => {
        toast.error('An error occurred while sending your message')

        setResults(results.filter((chat) => chat.cursor !== loadingId && chat.role === 'assistant'))
      })
  }

  const motionStyle = chatWithAI
    ? {
        transform: 'scale(1)', // Active state, no scaling down
        transition:
          'transform cubic-bezier(0, 1.2, 1, 1) 0.3s, opacity 0.1s ease-in-out, visibility 0.3s linear',
        opacity: 1,
        transformOrigin: 'bottom right',
      }
    : {
        transform: 'scale(0.1)', // Inactive state, scaled down
        opacity: 0,
        transition:
          'transform cubic-bezier(0, 1.2, 1, 1) 0.3s, opacity 0.1s ease-in-out, visibility 0.3s linear',
        transformOrigin: 'bottom right',
      }

  return (
    <AnimatePresence>
      <motion.div
        animate={{
          visibility: chatWithAI ? 'visible' : 'hidden',
        }}
        className="fixed bottom-[70px] right-4 sm:right-6 z-50"
      >
        <div className="relative">
          <div
            style={motionStyle}
            className="dark:shadow-2xl ml-4 sm:ml-0 shadow-xl dark:shadow-black/60 w-full sm:w-96 xl:w-[420px] overflow-hidden up-element rounded-xl"
          >
            <button
              onClick={() => setChatWithAI(false)}
              className="h-7 z-50 rounded-bl-xl rounded-none border-t-0 border-r-0 dark:hover:border-r-0 dark:hover:border-t-0 border-gray-100/40 absolute top-0 right-0 dashboard-secondary p-0 w-7"
            >
              <XIcon className="ml-[5px]" />
            </button>
            <div className="h-[554px]  flex flex-col">
              <div className="relative h-full p-3.5 pb-20 flex-col-reverse flex gap-5 custom-scrollbar overflow-y-auto mb-auto">
                {gettingData && (
                  <div className="flex items-center justify-center py-6">
                    <div className="w-6 h-6 secondary-svg">
                      <Loader />
                    </div>
                  </div>
                )}
                {results?.map((chat, index) => (
                  <div
                    key={chat.cursor}
                    className={cn('flex items-start gap-3', index === 0 ? 'mb-auto' : '')}
                  >
                    {chat.role === 'assistant' ? (
                      <div className="bg-primary inline-flex items-center justify-center rounded-full p-1.5">
                        <svg
                          className={cn(
                            'h-3.5 w-3.5 chatbot-rotate text-primary-foreground',
                            chat.cursor === loading && ' spin-inf'
                          )}
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_746_218)">
                            <path
                              d="M19.1064 5.83416C20.158 6.56113 20.9798 7.5734 21.4753 8.75178C21.9708 9.93015 22.1194 11.2256 21.9034 12.4855C21.6875 13.7455 21.1159 14.9175 20.2564 15.8636C19.3966 16.8096 18.2845 17.4905 17.051 17.8257C16.5252 17.966 15.9676 17.9289 15.4649 17.7204C14.9622 17.5117 14.5422 17.1434 14.27 16.6719C13.9978 16.2007 13.8887 15.6527 13.9593 15.113C14.03 14.5733 14.2765 14.072 14.6609 13.6866L16.5655 11.7805C17.3635 10.9869 17.983 10.0324 18.3831 8.98044C18.783 7.92853 18.954 6.80343 18.8848 5.68018C18.954 5.73046 19.031 5.78073 19.1064 5.83416Z"
                              fill="currentColor"
                            />
                            <path
                              d="M5.83416 2.89086C6.56162 1.83999 7.57408 1.01873 8.75243 0.523713C9.93076 0.0286976 11.226 -0.11949 12.4857 0.0965962C13.7454 0.312681 14.9172 0.884048 15.8633 1.74347C16.8093 2.6029 17.49 3.71462 17.8257 4.94786C17.9671 5.47402 17.9308 6.0321 17.7226 6.53556C17.5144 7.03903 17.1459 7.45973 16.6743 7.73243C16.2027 8.00514 15.6542 8.11461 15.114 8.04386C14.5738 7.97311 14.0721 7.7261 13.6866 7.34115L11.782 5.43658C10.9869 4.64077 10.0321 4.02268 8.98054 3.62315C7.92898 3.2236 6.80464 3.05167 5.68173 3.11872C5.73045 3.04329 5.7823 2.96629 5.83416 2.89086Z"
                              fill="currentColor"
                            />
                            <path
                              d="M2.89086 16.1631C1.83999 15.4357 1.01873 14.4232 0.523713 13.2449C0.0286976 12.0665 -0.11949 10.7713 0.0965962 9.51164C0.312681 8.25193 0.884028 7.08015 1.74345 6.13414C2.60287 5.18812 3.71461 4.50723 4.94785 4.17159C5.47456 4.02776 6.03405 4.06225 6.53911 4.2697C7.04416 4.47713 7.46641 4.84585 7.73998 5.31836C8.01353 5.79087 8.12306 6.34062 8.0515 6.8819C7.97992 7.42318 7.73126 7.92556 7.34428 8.31074L5.43972 10.2153C5.17872 10.4773 4.93564 10.7566 4.71213 11.0513C3.57219 12.5614 3.00831 14.4285 3.12186 16.3171C3.04486 16.2668 2.96786 16.2165 2.89086 16.1631Z"
                              fill="currentColor"
                            />
                            <path
                              d="M16.1648 19.1064C15.4374 20.1578 14.4249 20.9795 13.2463 21.4748C12.0678 21.9701 10.7722 22.1185 9.51222 21.9024C8.2522 21.6862 7.08014 21.1147 6.134 20.2549C5.18786 19.3952 4.50702 18.2831 4.1716 17.0494C4.02861 16.5228 4.06372 15.9635 4.27149 15.4588C4.47927 14.9541 4.84802 14.5323 5.32039 14.2589C5.79276 13.9855 6.34227 13.876 6.88338 13.9472C7.42449 14.0185 7.92685 14.2666 8.31233 14.653L10.2169 16.5575C10.4778 16.8198 10.7572 17.063 11.0529 17.2851C12.562 18.4258 14.429 18.9898 16.3172 18.8754C16.2685 18.9524 16.2167 19.0309 16.1648 19.1064Z"
                              fill="currentColor"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_746_218">
                              <rect width={22} height={22} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    ) : (
                      <img src={user?.profilePicture} className="h-[26px] rounded-full  w-[26px]" />
                    )}
                    <div className="flex flex-col gap-1">
                      <p className="text-sm flex items-center font-semibold dark:text-gray-100">
                        {chat.role === 'assistant' ? 'Featurebase Assistant' : user?.name}
                        {chat.role === 'assistant' && (
                          <span className="px-1.5 ml-2 py-1 leading-none inline-block font-medium text-[11px] bg-accent/20 rounded dark:text-accent-foreground text-primary-modified">
                            Beta
                          </span>
                        )}
                      </p>
                      {chat.cursor === loading ? (
                        <div className="flex flex-col mt-1">
                          <div className="w-full flex flex-col gap-2 animate-pulse">
                            <div className="h-3 bg-background-accent/[15%] dark:bg-border rounded w-64"></div>
                            <div className="h-3 bg-background-accent/[15%] dark:bg-border rounded w-56"></div>
                            <div className="h-3 bg-background-accent/[15%] dark:bg-border rounded w-48"></div>
                            <div className="h-3 bg-background-accent/[15%] dark:bg-border rounded w-52"></div>
                          </div>
                        </div>
                      ) : (
                        <div className="text-sm flex flex-col gap-1">
                          {chat?.html && (
                            <ContentModifier
                              content={chat?.html?.replace(
                                'REPLACE_WITH_USER_NAME',
                                user?.name?.split(' ')[0] || 'user'
                              )}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="relative">
                <div className="absolute inset-x-0 h-20 -top-[80px] bg-gradient-to-b from-transparent to-background pointer-events-none"></div>
                <div className="flex max-w-full absolute -top-[45px] pt-1 px-3 items-center overflow-auto custom-scrollbar gap-2 pb-1.5">
                  {/* {org.subscriptionStatus === 'trial_ended' || && (
                    <Button
                      onClick={() => {}}
                      variant={'outline'}
                      className="px-1.5 text-[11px] h-7 flex-shrink-0"
                      size={'sm'}
                    >
                      🕒 Extend my trial
                    </Button>
                  )} */}
                  {/* <Button
                    onClick={() => {
                      show()
                      setChatWithAI(false)
                    }}
                    variant={'outline'}
                    className="px-1.5 text-[11px] h-7 flex-shrink-0"
                    size={'sm'}
                  >
                    📞 Talk to sales
                  </Button> */}
                  <Button
                    onClick={() => {
                      window.postMessage({
                        target: 'FeaturebaseWidget',
                        data: { action: 'openFeedbackWidget', setBoard: '🐛 Bugs' },
                      })
                    }}
                    variant={'outline'}
                    className="px-1.5 h-7 text-[11px] flex-shrink-0"
                    size={'sm'}
                  >
                    🐞 Report a bug
                  </Button>
                  <Button
                    onClick={() => {
                      window.postMessage({
                        target: 'FeaturebaseWidget',
                        data: { action: 'openFeedbackWidget', setBoard: '💡 Feature Request' },
                      })
                    }}
                    variant={'outline'}
                    className="px-1.5 h-7 text-[11px] flex-shrink-0"
                    size={'sm'}
                  >
                    ✨ Request a feature
                  </Button>
                  <Button
                    onClick={() => {
                      window.postMessage({
                        target: 'FeaturebaseWidget',
                        data: { action: 'openFeedbackWidget', setBoard: '📥 Feedback' },
                      })
                    }}
                    variant={'outline'}
                    className="px-1.5 h-7 text-[11px] flex-shrink-0"
                    size={'sm'}
                  >
                    📝 Give us feedback
                  </Button>
                  <Button
                    onClick={() => {
                      window.open('https://www.featurebase.app/book-demo', '_blank')
                    }}
                    variant={'outline'}
                    className="px-1.5 h-7 text-[11px] flex-shrink-0"
                    size={'sm'}
                  >
                    🎥 Book a demo
                  </Button>
                </div>
                <div className="flex items-start relative z-20 border-t bg-white dark:bg-secondary">
                  <div className="pr-3 flex-1">
                    <textarea
                      value={textInput}
                      onChange={handleInputChange}
                      autoFocus={true}
                      ref={textareaRef}
                      tabIndex={1}
                      style={{
                        height: 'inherit',
                        resize: 'none',
                      }}
                      className="border-0 chatbot-textarea outline-none whitespace-pre-wrap break-words custom-scrollbar-stronger ring-0 focus:ring-0 max-h-[100px] p-4 bg-transparent dark:bg-transparent"
                      placeholder="Write your message"
                      rows={1}
                      // When enter is pressed, submit the message
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          // Check if Shift is not held down
                          e.preventDefault() // Prevent the default action (new line)
                          if (textInput.trim()) {
                            // Only submit if there's text
                            submitMessage()
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="p-3">
                    <button
                      onClick={() => submitMessage()}
                      className={cn(
                        'dark:hover:bg-dark-accent hover:bg-white border border-transparent hover:border-border dark:hover:border-dark-accent-foreground/5 hover:shadow',
                        loading && 'pointer-events-none'
                      )}
                    >
                      <ChevronRightIcon className="secondary-svg !h-4 !w-4 flex-shrink-0" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default FBAIBot
