import { ISubmission } from '@/interfaces/ISubmission'
import React from 'react'
import VotingHandler from './VotingHandler'
import CommentCounter from './CommentCounter'
import QuarterBadge from './QuarterBadge'
import Category from './Category'
import Status from './Status'
import { KeyedMutator } from 'swr'
import Link from '@/components/CustomLink'
import TagComponent from './Tag'
import { useUser } from '@/data/user'
import { useCurrentOrganization } from '@/data/organization'
import { can } from '@/lib/acl'

const RoadmapElement: React.FC<{
  entry: ISubmission
  setActiveSubmissionId: Function
  setMainPostView: Function
  mutateSubmissions: KeyedMutator<any>
  rawSubmissionData: any
  filterMode: string
  enableRedirection?: boolean
  setUrl?: any
}> = React.memo(
  ({
    entry,
    setActiveSubmissionId,
    setMainPostView,
    mutateSubmissions,
    rawSubmissionData,
    filterMode,
    enableRedirection,
    setUrl,
  }) => {
    const { user } = useUser()
    const { org } = useCurrentOrganization()

    const hideStatus =
      user && can(user?.id, 'set_post_status', org)
        ? false
        : org?.settings?.hideStatusFromPublic || false

    return (
      // <motion.div className="overflow-hidden" initial={{ height: 0 }} animate={{ height: 'auto' }}>
      <div className="relative dark:ring-1 dark:ring-black/10 group  min-w-0 p-3 mr-2 ml-1 mb-3 border rounded-lg shadow-sm hover:border-gray-100 hover:bg-gray-50 bg-white dark:shadow dark:bg-secondary/70 dark:hover:bg-secondary dark:border-border main-transition border-gray-100/60 ">
        {enableRedirection ? (
          <Link href={`?/p/${entry.slug}`} as={`/p/${entry.slug}`} shallow={true}>
            <a
              className="absolute inset-0 rounded-lg"
              onClick={() => {
                setActiveSubmissionId(entry.id)
                setMainPostView(true)
                setUrl(`/p/${entry.slug}`)
              }}
              aria-hidden="true"
            ></a>
          </Link>
        ) : (
          <button
            className={'unstyled-button absolute inset-0 rounded-lg'}
            onClick={() => {
              setActiveSubmissionId(entry.id)
              setMainPostView(true)
            }}
          ></button>
        )}
        <div className="">
          <a className="overflow-hidden text-sm font-semibold text-gray-500  line-clamp-3 content dark:text-gray-100 group-hover:underline decoration-gray-100 dark:decoration-gray-500">
            {entry.title}
          </a>

          <div className="flex flex-wrap items-end justify-between h-full mt-1 gap-y-1 gap-x-2">
            {filterMode !== 'status' && !hideStatus && (
              <div className="z-20 inline-block">
                <Status
                  status={entry.postStatus}
                  dashboard={true}
                  small={true}
                  postId={entry.id}
                  mutateSubmissions={mutateSubmissions}
                  rawSubmissionData={rawSubmissionData}
                  submission={entry}
                />
              </div>
            )}
            <div className="flex flex-wrap gap-2 mt-1">
              <div className="z-20 inline-block">
                <Category
                  mutateSubmissions={mutateSubmissions}
                  small={true}
                  postId={entry.id}
                  rawSubmissionData={rawSubmissionData}
                  dash={true}
                  category={entry.postCategory}
                />
              </div>
              {entry?.eta && filterMode === 'status' && (
                <div className="z-20">
                  <QuarterBadge date={entry?.eta} />
                </div>
              )}
              {entry.postTags?.length !== 0 && (
                <div className="z-20 inline-flex items-center">
                  <TagComponent
                    dash={true}
                    mutateSubmissions={mutateSubmissions}
                    postId={entry.id}
                    rawSubmissionData={rawSubmissionData}
                    tags={entry.postTags}
                    small={true}
                  />
                </div>
              )}
              {entry?.commentCount ? (
                <div className="z-20">
                  <CommentCounter count={entry.commentCount} />
                </div>
              ) : null}
            </div>
            <div className="z-10 flex flex-col mt-1 ml-auto">
              <VotingHandler
                small={true}
                rawSubmissionData={rawSubmissionData}
                submission={entry}
                mutateSubmissions={mutateSubmissions}
              />
            </div>
          </div>
        </div>
      </div>
      // </motion.div>
    )
  }
)

RoadmapElement.displayName = 'RoadmapElement'

export default RoadmapElement
