import { ISubmissionFilters } from '../interfaces/ISubmission'
import { atom } from 'jotai'

export const defaultFilters: ISubmissionFilters = {
  sortBy: 'date:desc',
  advancedFilters: [],
}

export const postsFilterAtom = atom(defaultFilters)

export const helpcenterActiveLocaleAtom = atom('en')
