import Link from '@/components/CustomLink'
import { Disclosure, Transition } from '@headlessui/react'
import { MenuIcon, TranslateIcon, XIcon } from '@heroicons/react/solid'
import {
  helpcenterActivePageAtom,
  helpcenterShowBackButtonAtom,
  helpCenterUrlPartsAtom,
} from '@/atoms/orgAtom'
import { useAtom } from 'jotai'
import Loader from './Loader'
import { useKnowledgebaseStructure } from '@/data/knowledgebase'
import { useEffect, useMemo, useState } from 'react'
import { IHelpCenterArticleDocument } from '@/interfaces/IHelpCenter'
import Image from 'next/image'
import { useCurrentOrganization } from '@/data/organization'
import NavItemNormal from './docs/NavItemNormal'
import NavItemCollapsed from './docs/NavItemCollapsed'
import MenuContent from './docs/MenuContent'
import ChangelogLocaleDropdown from './DashboardLocaleDropdown'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { getCountryNameByCode } from '@/lib/localizationUtils'

export type navData = navItemType[]

export type navItemType = {
  name: string
  current: boolean
  href: string
  icon: IHelpCenterArticleDocument['icon']
  slug: string
  id: string
  pages: navItemType[]
}

export const processNavData = (
  data: any,
  urlParts: {
    subpath: string
    locale: string
  }
) => {
  const processItem = (item: any): any => ({
    name: item.type === 'collection' ? item.name : item.title,
    href: `${urlParts.subpath}${item.type === 'collection' ? 'collections' : 'articles'}/${
      item.slug
    }`,
    icon: item?.icon,
    slug: item.slug,
    pages: item.structure ? item.structure.map(processItem) : [],
    id: item?.articleId || item?.collectionId,
  })

  return data?.structure?.map(processItem) || []
}

const Navigation = () => {
  const { data } = useKnowledgebaseStructure()

  const [helpCenterUrlParts] = useAtom(helpCenterUrlPartsAtom)
  const [showBackButton, setShowBackButton] = useAtom(helpcenterShowBackButtonAtom)
  const [activePage, setActivePage] = useAtom(helpcenterActivePageAtom)

  const { org } = useCurrentOrganization()

  const { i18n } = useTranslation()

  const router = useRouter()

  const [navData, setNavData] = useState<navData>(processNavData(data, helpCenterUrlParts))

  const memoizedNavItems = useMemo(
    () => (close: () => void) => {
      let filteredNavData = showBackButton?.show
        ? navData.filter((item) => showBackButton?.collection?.collectionId === item.id)
        : navData

      // Set pages as root items if showBackButton is set
      if (showBackButton?.show) {
        // filteredNavData = filteredNavData.flatMap((item) => item.pages)
        filteredNavData =
          filteredNavData?.[0]?.pages?.map((page) => ({
            ...page,
            current: false,
            pages: page.pages,
          })) || []
      }

      return filteredNavData
        .filter((navItem) => navItem.pages?.length > 0)
        .map((navItem, index) =>
          filteredNavData.length > 4 || showBackButton?.show ? (
            <NavItemCollapsed key={navItem.slug} navItem={navItem} index={index} close={close} />
          ) : (
            <NavItemNormal key={navItem.slug} navItem={navItem} index={index} close={close} />
          )
        )
    },
    [navData, showBackButton?.show]
  )

  const MemoizedMenuContent = useMemo(() => {
    const MenuContentWrapper = ({ closeFunc }: { closeFunc: () => void }) => (
      <MenuContent close={closeFunc} memoizedNavItems={memoizedNavItems(closeFunc)} data={data} />
    )
    MenuContentWrapper.displayName = 'MemoizedMenuContent'
    return MenuContentWrapper
  }, [memoizedNavItems, data])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [])

  const handleChangeLocale = (locale: string) => {
    i18n.changeLanguage(locale)
    router.push(router.asPath, undefined, { locale })
  }

  return (
    <>
      <Disclosure as="nav">
        {({ open, close }) => (
          <>
            <div className="relative top-0 left-0 z-10 h-full border-r shadow-sm xl:min-h-screen xl:fixed dark:border-border/60 shadow-gray-200/20 dark:shadow-md dashboard-border">
              <div className="z-50 h-full custom-scrollbar-stronger overscroll-contain rounded-none w-[278px] flex-col justify-between hidden overflow-x-hidden xl:flex dark:border-border ">
                <MemoizedMenuContent closeFunc={close} />
              </div>
            </div>
            <div className="flex items-center justify-between w-full px-4 py-4 border-b dark:bg-secondary/40 dashboard-border xl:hidden">
              <Link className="w-full truncate" href={`${helpCenterUrlParts.subpath}`}>
                <button className="inline-flex items-center max-w-full truncate unstyled-button">
                  <div className="flex items-center gap-2.5">
                    <div className="flex items-center justify-center flex-shrink-0 mr-0 overflow-hidden rounded-full w-7 h-7 sm:h-7 sm:w-7 dark:bg-secondary dark:border-secondary">
                      {org?.picture ? (
                        <Image
                          className="object-cover rounded-full"
                          src={org?.picture}
                          height={28}
                          width={28}
                          alt="profile_pic"
                        />
                      ) : (
                        <div className="flex items-center justify-center text-gray-400 dark:text-background-accent">
                          <div className="w-5 h-5 ">
                            <Loader />
                          </div>
                        </div>
                      )}
                    </div>
                    <h2 className="w-full mt-px text-sm font-semibold leading-none text-gray-600 truncate transform-gpu sm:text-base dark:text-gray-50">
                      {org?.displayName}
                    </h2>
                  </div>
                </button>
              </Link>
              <div className="flex items-center gap-2">
                {data?.availableLocales?.length > 1 && (
                  <div className="xl:hidden">
                    <ChangelogLocaleDropdown
                      publicView={true}
                      hideNewLanguageSuggestion={true}
                      activeLocale={i18n.language === 'default' ? 'en' : i18n.language}
                      availableLocales={data?.availableLocales}
                      changeLocale={(locale) => {
                        handleChangeLocale(locale)
                      }}
                      customButton={
                        <button className="h-8 px-2 dashboard-secondary bg-transparent leading-none text-xs font-semibold">
                          <TranslateIcon className="secondary-svg mr-1" />{' '}
                          {getCountryNameByCode(i18n.language === 'default' ? 'en' : i18n.language)}
                        </button>
                      }
                    />
                  </div>
                )}
                <Disclosure.Button className="h-8 px-2 dashboard-secondary">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
            <div className="relative z-[100]">
              <Transition
                appear={true}
                show={open}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100 "
                leave="ease-in duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Disclosure.Panel className="fixed inset-0 flex flex-col justify-between pb-3 bg-gray-50/80 xl:hidden dark:bg-background/[85%] backdrop-blur-md">
                  <MemoizedMenuContent closeFunc={close} />
                </Disclosure.Panel>
              </Transition>
            </div>
          </>
        )}
      </Disclosure>
    </>
  )
}

Navigation.theme = 'light'
export default Navigation
