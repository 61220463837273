import { useMemo } from 'react'
import { Editor, useEditor } from '@tiptap/react'
import { ExtensionKit } from '@/components/editor/extensions/extension-kit'
// import { EditorUser } from '@/components/editor/components/BlockEditor/types'
import { useDebouncedCallback } from 'use-debounce'
import {
  handleImageDrop,
  handleImagePaste,
} from '@/components/editor/extensions/HandleNonCommandImage/regular-upload-images'
import { uploadFn } from '@/components/editor/image-upload'
import MarkdownIt from 'markdown-it'
import DOMPurify from 'dompurify'

declare global {
  interface Window {
    editor: Editor | null
  }
}

// Function to remove color from style attribute
const removeColorFromStyle = (element: HTMLElement) => {
  if (element.style.color) {
    element.style.removeProperty('color')
  }
  for (let i = 0; i < element.children.length; i++) {
    removeColorFromStyle(element.children[i] as HTMLElement)
  }
}

export const useBlockEditor = (
  instanceId: string,
  content: string,
  setNewContent: (content: string) => any,
  compactMode: boolean,
  staticPlaceholderRefWrapper?: any,
  height?: number,
  editorRef?: any,
  dontAutoFocus?: boolean,
  changelog?: boolean
) => {
  // const [collabState, setCollabState] = useState<WebSocketStatus>(WebSocketStatus.Connecting)

  const debouncedUpdates = useDebouncedCallback(async (editor: any) => {
    setNewContent(editor.getHTML())
  }, 100)

  const editor = useEditor(
    {
      autofocus: false,
      // onCreate: ({ editor }) => {
      //   provider?.on('synced', () => {
      //     if (editor.isEmpty) {
      //       editor.commands.setContent(initialContent)
      //     }
      //   })
      // },
      onUpdate: ({ editor }) => {
        debouncedUpdates(editor)
      },
      extensions: [
        ...ExtensionKit(instanceId, compactMode, staticPlaceholderRefWrapper, changelog),
        // Collaboration.configure({
        //   document: ydoc,
        // }),
        // CollaborationCursor.configure({
        //   provider,
        //   user: {
        //     name: randomElement(userNames),
        //     color: randomElement(userColors),
        //   },
        // }),
      ],
      editorProps: {
        handlePaste: (view, event) => {
          try {
            // Handle image paste first
            const handled = handleImagePaste(view, event, uploadFn)
            if (handled) return true

            const clipboardData = event.clipboardData
            if (!clipboardData) {
              return false
            }

            const text = clipboardData.getData('text/plain')
            const htmlData = clipboardData.getData('text/html')

            // If HTML data is available, clean and sanitize it
            if (htmlData) {
              event.preventDefault()

              // Create a temporary div to parse the HTML
              const tempDiv = document.createElement('div')
              tempDiv.innerHTML = htmlData
              removeColorFromStyle(tempDiv)
              const cleanHtml = tempDiv.innerHTML

              // Sanitize the HTML
              const sanitizedHtml = DOMPurify.sanitize(cleanHtml)

              // Insert the sanitized HTML into the editor
              view.pasteHTML(sanitizedHtml)
              return true
            } else if (text) {
              // Check if the text likely contains Markdown syntax
              const markdownSyntaxRegex =
                /(^\s{0,3}(?:[#>-]|\d+\.|\*|\+|-)\s)|(\*\*|__|\*|_|`{1,3}|~{2})/m
              const isMarkdown = markdownSyntaxRegex.test(text)

              if (isMarkdown) {
                // Parse Markdown to HTML
                const markdownIt = new MarkdownIt()
                const html = markdownIt.render(text)

                // Sanitize the generated HTML
                const sanitizedHtml = DOMPurify.sanitize(html)

                event.preventDefault()

                // Insert the sanitized HTML into the editor
                view.pasteHTML(sanitizedHtml)

                return true
              } else {
                // If it's plain text, let the default behavior handle it
                return false
              }
            }
          } catch (error) {
            console.error('Error handling paste event:', error)
            // Optionally, display an error message to the user
            return false
          }
          return false
        },
        handleDrop: (view, event, _slice, moved) => handleImageDrop(view, event, moved, uploadFn),
        attributes: {
          class: !compactMode || changelog ? 'installation-content' : '',
          // class: 'min-h-full min-h-',
          spellcheck: 'true',
          tabindex: dontAutoFocus ? '-1' : '0', // Add this line
          // style: `min-height: ${height}px; padding-bottom: ${changelog ? 32 : 12}px;`,
          style: `min-height: ${height ? height : 100}px;  padding-bottom: ${12}px;`,
        },
      },
      onCreate: ({ editor }) => {
        if (editorRef) {
          editorRef.current = editor
        }
      },
      onBeforeCreate({ editor }) {
        if (editorRef) {
          editorRef.current = editor
        }
      },
      content: content,
    }
    // [ydoc, provider]
  )

  // const users = useMemo(() => {
  //   if (!editor?.storage.collaborationCursor?.users) {
  //     return []
  //   }

  //   return editor.storage.collaborationCursor?.users.map((user: EditorUser) => {
  //     const names = user.name?.split(' ')
  //     const firstName = names?.[0]
  //     const lastName = names?.[names.length - 1]
  //     const initials = `${firstName?.[0] || '?'}${lastName?.[0] || '?'}`

  //     return { ...user, initials: initials.length ? initials : '?' }
  //   })
  // }, [editor?.storage.collaborationCursor?.users])

  const characterCount = editor?.storage.characterCount || { characters: () => 0, words: () => 0 }

  // useEffect(() => {
  //   provider?.on('status', (event: { status: WebSocketStatus }) => {
  //     setCollabState(event.status)
  //   })
  // }, [provider])

  return { editor, characterCount }
}
