import React, { useState } from 'react'
import { SelectSingleEventHandler } from 'react-day-picker'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'
import { Button } from './Button'
import { CalendarIcon } from '@heroicons/react/solid'
import { format } from 'date-fns'
import { cn, retrieveDateWithoutTimezone } from '@/lib/utils'
import { Calendar } from './Calendar'
import { DateTime } from 'luxon'

type DatePickerProps = {
  selected: Date | undefined
  buttonClasses?: string
  customPlaceholder?: string
  CustomButton?: JSX.Element
  showQuarterButtons?: boolean
  popoverContentProps?: any
  exactTimeSelection?: boolean
} & (
  | { unscheduling: true; onSelect: (date: Date | null) => void }
  | { unscheduling?: undefined; onSelect: (date: Date) => void }
)

export function DatePicker(props: DatePickerProps) {
  const {
    selected,
    buttonClasses,
    customPlaceholder,
    CustomButton,
    showQuarterButtons,
    popoverContentProps,
  } = props

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [timeSelectionCachedDate, setTimeSelectionCachedDate] = useState<Date | undefined>(selected)

  const timeZone = 'Etc/UTC'

  const handleOnSelect: SelectSingleEventHandler = (date) => {
    if (props?.exactTimeSelection) {
      setTimeSelectionCachedDate(date)
    } else {
      // Normalize the date
      if (props.unscheduling && !date) {
        props.onSelect?.(null)
      } else if (date) {
        if (typeof date === 'string') {
          date = retrieveDateWithoutTimezone(date) as Date
        }
        // Get the date components in the user's local timezone
        const year = date.getFullYear()
        const month = date.getMonth() // 0-11
        const day = date.getDate()

        // Create a new Date object using these components
        // Set the time to noon to avoid any daylight saving time issues
        const storedDate = new Date(year, month, day, 12, 0, 0, 0)

        props.onSelect(storedDate)
      }
      setIsPopoverOpen(false)
    }
    setIsPopoverOpen(false)
  }

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        {CustomButton ? (
          CustomButton
        ) : (
          <Button
            variant={'outline'}
            className={cn(!selected && 'text-muted-foreground ', buttonClasses, 'truncate')}
          >
            <CalendarIcon className="flex-shrink-0 w-4 h-4 mr-2 secondary-svg" />
            <span className="truncate">
              {selected
                ? format(new Date(selected), 'MMM dd, yyyy')
                : customPlaceholder
                ? customPlaceholder
                : 'Pick a date'}
            </span>
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent
        disableResizeAnimation={true}
        className="w-auto p-0"
        align="end"
        {...popoverContentProps}
      >
        <Calendar
          mode="single"
          selected={
            props?.exactTimeSelection
              ? timeSelectionCachedDate
              : DateTime.fromJSDate(selected || new Date(), { zone: timeZone })
                  .setZone('local', { keepLocalTime: false })
                  .toJSDate()
          }
          onSelect={handleOnSelect}
          initialFocus
          defaultMonth={selected ? DateTime.fromJSDate(selected).toUTC().toJSDate() : undefined}
          showQuarterButtons={showQuarterButtons}
          unscheduling={props.unscheduling}
        />
      </PopoverContent>
    </Popover>
  )
}
